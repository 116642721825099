import React from "react"
import SiteMetadata from "../components/site-metadata"
import Container from "../components/container"
import logo from "../assets/images/PieBeatsCake-white-gt-printaura3.png"

export default () =>   (<div>
  <SiteMetadata></SiteMetadata>
  <Container>
  {/*Emojis by Twitter (Twemoji - https://github.com/twitter/twemoji) licensed under Creative Commons 4 (https://creativecommons.org/licenses/by/4.0/deed.ast)*/}
  <section>
    <header>
      <img src={logo} alt="Pie Beats Cake logo" height={600}/>
      <h1>Pie Beats Cake</h1>
      <h2>Coming Soon!</h2>
      <h4>Etsy: <a href="https://www.etsy.com/shop/PieBeatsCake">Pie Beats Cake</a></h4>
      <h4>Info: <a href="mailto://jagjeet@piebeatscake.com">jagjeet@piebeatscake.com</a></h4>
    </header>
  </section>
  </Container>
</div>
)
